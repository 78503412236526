<template>
  <div id="meeting-list-page">
    <div class="content" v-show="authSuccess">
      <div class="head">
        <div class="display-name">
          <a-icon class="icon-user" type="user" />
          <div class="name">{{ curUserDisplayName | ellipsisByLength(8) }}</div>
        </div>
        <div class="exit-btn" @click="handleLogout">退出</div>
      </div>
      <div class="main">
        <div class="aside left">
          <a-spin :indicator="indicator" :spinning="isCreatingInstant">
            <div
              class="meeting-btn instant"
              @click="createInstantMeetingAndJoin"
            >
              即时开会
            </div>
          </a-spin>
          <div class="meeting-btn invite" @click="inviteUsers">邀请入会</div>
        </div>
        <div class="aside right">
          <div class="tab">
            <div
              :class="['tab-item', curType === item.key ? 'actived' : '']"
              v-for="item in meetingTypes"
              :key="item.key"
              @click="handleMeetingTypeChange(item)"
            >
              {{ item.title }}
            </div>
            <div
              class="tools-btn search-btn"
              v-if="curType !== 'instantMeeting'"
              @click="toggleSearch"
            ></div>
            <div class="tools-btn refresh-btn" @click="onRefresh"></div>
          </div>
          <a-input-search
            v-if="searchShow"
            class="search-input"
            allow-clear
            v-model="curKeyword"
            @search="onSearch"
          />
          <template v-if="curList.length > 0">
            <div class="meeting-list">
              <div
                class="meeting-item"
                v-for="item in curList"
                :key="item.roomId"
              >
                <div class="info">
                  <div
                    class="title"
                    :title="item.roomName"
                    v-if="isCommonMeeting || isScheduleMeeting"
                  >
                    {{ item.roomName }}
                  </div>
                  <div
                    class="title"
                    :title="item.meetingName"
                    v-if="isInstantMeeting"
                  >
                    {{ item.meetingName }}
                  </div>
                  <div class="extra">
                    <div v-if="isCommonMeeting" class="user-count">
                      {{ item.curUserCount }}/{{ item.maxUserCount }}
                    </div>
                    <div v-if="isScheduleMeeting" class="meeting-time">
                      {{ item.hopeStartTime | formatMeetingTime }}
                      &nbsp;&nbsp;
                      {{ item.hopeEndTime | formatMeetingTime }}
                    </div>
                    <div>邀请码：{{ item.inviteCode }}</div>
                  </div>
                </div>
                <div
                  class="enter-btn"
                  @click="joinMeeting({ inviteCode: item.roomId })"
                >
                  加入
                </div>
              </div>
            </div>
            <div
              class="pagination"
              v-if="curType !== 'instantMeeting' && curTotal > 0"
            >
              <a-button
                icon="left"
                :disabled="!previousEnable"
                @click="handlePreviousPage"
              />
              <div class="cur-page">{{ curPage }} / {{ curMaxPage }}</div>
              <a-button
                icon="right"
                :disabled="!nextEnable"
                @click="handleNextPage"
              />
            </div>
          </template>
          <div class="no-data" v-else>
            <img src="@/assets/img/meeting-list/blank.png" alt="" />
            <div>暂无参加的会议</div>
          </div>
        </div>
      </div>
    </div>
    <address-book-modal
      ref="addressBookModalRef"
      v-model="addressBookVisible"
      @ok="onInviteOk"
    />
    <be-invited-modal
      :inviter-name="inviterName"
      v-model="beInvitedVisible"
      @response="responseInvite"
    />
    <force-logout v-model="forceLogoutShow" :user-name="userAccount" />
  </div>
</template>

<script>
import moment from 'moment'
import clonedeep from 'lodash.clonedeep'
import { mapActions, mapGetters } from 'vuex'
import AddressBookModal from '@/components/addressBook/AddressBookModal'
import BeInvitedModal from '@/components/beInvitedModal'
import ForceLogout from '@/components/forceLogout'
import './index.less'
import { getStorage } from '@/utils/util'

const PAGE_SIZE = 5

export default {
  name: 'MeetingList',
  components: { AddressBookModal, BeInvitedModal, ForceLogout },
  data() {
    return {
      authSuccess: false,
      userLoginInfo: {},
      indicator: <a-icon type="loading" spin />,
      isCreatingInstant: false,
      meetingTypes: [
        {
          key: 'commonMeeting',
          title: 'Cloud Meeting Room',
        },
        {
          key: 'scheduleMeeting',
          title: 'Scheduled Meeting',
        },
        {
          key: 'instantMeeting',
          title: 'Instant Meeting',
        },
      ],
      searchShow: false,
      curType: 'commonMeeting',
      curPage: 1,
      curMaxPage: 1,
      curKeyword: '',
      curList: [],
      curTotal: 0,
      addressBookVisible: false,
      beInvitedVisible: false,
      beInvitedInfo: undefined,
      beInvitedInfoParams: {},
      forceLogoutShow: false,
    }
  },
  computed: {
    ...mapGetters(['hstWebEngine']),
    isCommonMeeting() {
      return this.curType === 'commonMeeting'
    },
    isScheduleMeeting() {
      return this.curType === 'scheduleMeeting'
    },
    isInstantMeeting() {
      return this.curType === 'instantMeeting'
    },
    previousEnable() {
      return this.curPage > 1
    },
    nextEnable() {
      const { curPage, curList, curTotal } = this
      return (curPage - 1) * PAGE_SIZE + curList.length < curTotal
    },
    curUserDisplayName() {
      return this.userLoginInfo.displayName
    },
    inviterName() {
      const { inviter = {} } = this.beInvitedInfoParams
      const { userName, userId } = inviter
      return userName || userId || ''
    },
    userAccount() {
      return this.$route.query.userAccount
    },
  },
  filters: {
    formatMeetingTime(value) {
      if (!value) return ''
      return moment(value).format('MM-DD HH:mm')
    },
    ellipsisByLength(value, length) {
      if (!value) return ''
      if (value.length > length) {
        return value.slice(0, length) + '...'
      } else {
        return value
      }
    },
  },
  created() {
    const { cesAddr, loginTab } = this.$route.query
    loginTab && window.sessionStorage.setItem('loginTab', loginTab)
    const clientType = process.env.VUE_APP_CLIENT_TYPE
    const { sdkInitSuccess, sdkInitFail } = this
    this.initWebEngine(cesAddr ? { cesAddr, clientType } : { clientType })
      .then(sdkInitSuccess)
      .catch(sdkInitFail)
  },
  beforeDestroy() {
    this.hstWebEngine.off('onCommingInvite')
    this.hstWebEngine.off('onUserForceLogout')
    this.hstWebEngine.off('onIgnoreInvite')
    window.removeEventListener('offline', this.closeBeInvitedModal)
  },
  methods: {
    ...mapActions(['initWebEngine']),
    // 监听被呼叫
    onCommingInvite() {
      this.hstWebEngine.on('onCommingInvite', res => {
        if (this.beInvitedInfo) return
        this.beInvitedInfo = res
        this.beInvitedVisible = true
        // 60s自动拒绝
        this.autoRefuseTimer = setTimeout(() => {
          this.responseInvite(false)
        }, 60_000)
        try {
          const extendInfo = JSON.parse(res.extendInfo)
          this.beInvitedInfoParams = extendInfo.params
        } catch (error) {
          console.log('hstWebEngine onCommingInvite error', error)
        }
      })
      this.hstWebEngine.on('onIgnoreInvite', () => {
        // 其他端已处理这次呼叫
        this.closeBeInvitedModal()
      })
    },
    errorHandler(content) {
      this.$error({
        content,
        onOk: () => {
          this.$router.replace({ path: '/login' })
        },
      })
    },
    async sdkInitSuccess() {
      try {
        const { userAccount } = this.$route.query
        const userPass = getStorage('userPass')
        await this.hstWebEngine.accountAuth({
          userName: userAccount,
          password: userPass,
          needLoginInfo: true,
        })
        this.authSuccess = true
        localStorage.setItem('login_userAccount', userAccount)
        localStorage.setItem('login_userPass', userPass)
        this.userLoginInfo = this.hstWebEngine.getUserLoginInfo()
        this.queryCommonMeeting()
        this.onCommingInvite()
        this.onUserForceLogout()
        window.addEventListener('offline', this.closeBeInvitedModal)
      } catch (error) {
        this.authSuccess = false
        if (error?.result === 8449) {
          this.errorHandler('用户名或密码错误，请重新输入')
        } else {
          this.errorHandler(error.data)
        }
      }
    },
    sdkInitFail(error) {
      this.errorHandler(error.msg)
    },
    // 账号被挤掉
    onUserForceLogout() {
      this.hstWebEngine.on('onUserForceLogout', () => {
        this.forceLogoutShow = true
      })
    },
    handleLogout() {
      this.$confirm({
        title: '您确定退出当前登录账号?',
        onOk: () => {
          this.$router.replace({ path: '/login' })
        },
      })
    },
    // 创建即时会议
    async createInstantMeeting(meetingType) {
      try {
        const defaultParams = {
          meetingName: `${this.curUserDisplayName}的会议`,
          verifyMode: 3, // 匿名登录
          userList: [],
          maxUserCount: 30,
          meetingTemplate: 3, // 讨论型
        }
        if (meetingType === 1) {
          defaultParams.meetingType = meetingType
        }
        const res = await this.hstWebEngine.createInstantMeeting(defaultParams)
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, result } = res.data
        if (Number(resCode) === 1) {
          return Promise.resolve(result)
        } else if (Number(resCode) === 20829) {
          return Promise.reject('您没有权限操作')
        } else {
          return Promise.reject(
            resMessage || msg || `创建即时会议失败：${resCode}`
          )
        }
      } catch (error) {
        console.log('exception in hstWebEngine.createInstantMeeting: ', error)
      }
    },
    async createInstantMeetingAndJoin() {
      this.isCreatingInstant = true
      try {
        const { inviteCode } = await this.createInstantMeeting()
        this.isCreatingInstant = false
        this.joinMeeting({ inviteCode })
      } catch (error) {
        this.isCreatingInstant = false
        this.$message.error(error)
      }
    },
    // 邀请入会
    inviteUsers() {
      this.addressBookVisible = true
    },
    async onInviteOk(selectedUsersList) {
      const { okButtonProps } = this.$refs.addressBookModalRef
      okButtonProps.loading = true
      try {
        const { inviteCode, roomId } = await this.createInstantMeeting(1)
        const usersIdList = selectedUsersList.map(e => String(e.userId))
        this.hstWebEngine.inviteUser(usersIdList, inviteCode, roomId)
        okButtonProps.loading = false
        this.joinMeeting({ inviteCode })
      } catch (error) {
        okButtonProps.loading = false
        this.$message.error(error)
      }
    },
    toggleSearch() {
      this.searchShow = !this.searchShow
      if (!this.searchShow) {
        this.curKeyword = ''
        this.onSearch()
      }
    },
    onSearch() {
      this.curPage = 1
      this.fetchCurTypeData()
    },
    onRefresh() {
      this.curPage = 1
      this.fetchCurTypeData()
    },
    handleMeetingTypeChange({ key }) {
      this.curType = key
      this.curKeyword = ''
      this.curPage = 1
      this.searchShow = false
      this.curList = []
      this.fetchCurTypeData()
    },
    // 查询云会议室列表
    async queryCommonMeeting() {
      try {
        const res = await this.hstWebEngine.queryCommonMeeting({
          keywords: this.curKeyword,
          page: this.curPage,
          pageSize: PAGE_SIZE,
        })
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, roomList, totalCount } = res.data
        if (Number(resCode) === 1) {
          this.curList = roomList
          this.curTotal = totalCount
          this.curMaxPage = Math.ceil(totalCount / PAGE_SIZE)
        } else {
          this.curList = []
          this.curTotal = 0
          this.$message.error(
            resMessage || msg || `查询云会议室列表失败：${resCode}`
          )
        }
      } catch (error) {
        console.log('exception in hstWebEngine.queryCommonMeeting: ', error)
      }
    },
    // 查询预约会议列表
    async queryScheduleMeeting() {
      try {
        const res = await this.hstWebEngine.queryScheduleMeeting({
          recentDays: 7,
          keywords: this.curKeyword,
          page: this.curPage,
          pageSize: PAGE_SIZE,
        })
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, result } = res.data
        if (Number(resCode) === 1) {
          const { totalRowsAmount, items } = result
          this.curList = items
          this.curTotal = totalRowsAmount
          this.curMaxPage = Math.ceil(totalRowsAmount / PAGE_SIZE)
        } else {
          this.curList = []
          this.curTotal = 0
          this.$message.error(
            resMessage || msg || `查询预约会议列表失败：${resCode}`
          )
        }
      } catch (error) {
        console.log('exception in hstWebEngine.queryScheduleMeeting: ', error)
      }
    },
    // 查询即时会议列表
    async queryInstantMeeting() {
      try {
        const res = await this.hstWebEngine.queryInstantMeeting()
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, result } = res.data
        if (Number(resCode) === 1) {
          this.curList = result.items
          this.curTotal = result.items.length
        } else {
          this.curList = []
          this.curTotal = 0
          this.$message.error(
            resMessage || msg || `查询即时会议列表失败：${resCode}`
          )
        }
      } catch (error) {
        console.log('exception in hstWebEngine.queryInstantMeeting: ', error)
      }
    },
    handlePreviousPage() {
      this.curPage--
      this.fetchCurTypeData()
    },
    handleNextPage() {
      this.curPage++
      this.fetchCurTypeData()
    },
    // 请求当前类型的会议数据
    fetchCurTypeData() {
      const methodsMap = {
        commonMeeting: 'queryCommonMeeting',
        scheduleMeeting: 'queryScheduleMeeting',
        instantMeeting: 'queryInstantMeeting',
      }
      const curHandle = this[methodsMap[this.curType]]
      typeof curHandle === 'function' && curHandle()
    },
    // 处理登录失效
    executeLoginInvaild(res) {
      const { status } = res.data
      if ([401, 403].includes(status)) {
        this.errorHandler('登录已失效，请重新登录')
        return Promise.reject(res.data)
      }
      return Promise.resolve()
    },
    // 关闭被呼叫弹框
    closeBeInvitedModal() {
      this.beInvitedVisible = false
      this.autoRefuseTimer && clearTimeout(this.autoRefuseTimer)
      this.beInvitedInfo = undefined
    },
    // 应答入会邀请
    responseInvite(agree = true) {
      const beInvitedInfo = clonedeep(this.beInvitedInfo)
      this.closeBeInvitedModal()
      if (agree) {
        this.hstWebEngine.replyInvite(0, beInvitedInfo)
        const { inviteCode } = this.beInvitedInfoParams
        this.joinMeeting({ inviteCode })
      } else {
        this.hstWebEngine.replyInvite(1, beInvitedInfo)
      }
    },
    // 加入会议室
    joinMeeting({ inviteCode }) {
      const { cesAddr, loginTab, userAccount, userPass } = this.$route.query
      const query = {
        cesAddr,
        loginTab,
        userName: userAccount,
        userPass,
        roomId: inviteCode,
        type: 3, // 暂时写死
      }
      sessionStorage.setItem('meeting_page_from', 'meeting-list')
      this.$router.push({ path: '/meeting', query })
    },
  },
}
</script>
