<template>
  <a-modal
    class="be-invited-modal"
    :footer="null"
    :width="400"
    :mask="true"
    :mask-closable="false"
    :keyboard="false"
    destroy-on-close
    v-model="visible"
    @cancel="$emit('response', false)"
  >
    <audio ref="ringAudio" src="@/assets/music/ring.mp3" loop></audio>
    <div class="head">
      <div class="display-name">{{ inviterName }}</div>
      <div class="message">You are invited for a video call</div>
    </div>
    <div class="operate-btn-wrapper">
      <div class="operate-btn agree" @click="$emit('response', true)">
        <div class="icon"></div>
        <div>Accept</div>
      </div>
      <div class="operate-btn refuse" @click="$emit('response', false)">
        <div class="icon"></div>
        <div>Refuse</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'BeInvitedModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    inviterName: {
      type: String,
      default: '',
    },
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    visible(value) {
      this.$nextTick(() => {
        const { ringAudio } = this.$refs
        value ? ringAudio.play() : ringAudio.pause()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.be-invited-modal {
  .head {
    padding: 50px 25px 25px;
    background: linear-gradient(0deg, #363d57, #546286);
    color: #fff;
    text-align: center;
    .display-name {
      font-size: 24px;
    }
    .message {
      margin-top: 5px;
    }
  }
  .operate-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    .operate-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      color: #94959d;
      &:not(:first-child) {
        margin-left: 60px;
      }
      .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
        background-position: center;
        background-size: cover;
      }
      &.agree {
        .icon {
          background-image: url(~@/assets/img/inviteResponse/call_agree.png);
        }
        &:hover {
          .icon {
            background-image: url(~@/assets/img/inviteResponse/call_agree_hot.png);
          }
        }
      }
      &.refuse {
        .icon {
          background-image: url(~@/assets/img/inviteResponse/call_refuse.png);
        }
        &:hover {
          .icon {
            background-image: url(~@/assets/img/inviteResponse/call_refuse_hot.png);
          }
        }
      }
    }
  }
}
</style>
